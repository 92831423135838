import Layout from '@/layout'
const contentManagementRouter = {
    component: Layout,
    path: '/contentManagement',
    name: '内容管理',
    redirect: '/villager',

    meta: {
        title: '内容管理',
        icon: ''
    },
    children: [
        {
            path: '/contentManagement/villagerState',
            name: 'villagerState',
            meta: { title: "村居动态" },
            component: () => import('../views/contentManagement/villagerState/index.vue')
        },
        {
            path: '/contentManagement/villager',
            name: 'villager',
            meta: { title: "村民说事" },
            component: () => import('../views/contentManagement/villager/index.vue')
        },
        {
            path: "/contentManagement/secretary",
            name: "secretary",
            meta: { title: "书记公开信" },
            component: () => import('../views/contentManagement/secretary/index.vue')
        },
        {
            path: "/contentManagement/Disclosure",
            name: "Disclosure",
            meta: { title: "三务公开" },
            component: () => import('../views/contentManagement/Disclosure/index.vue')
        },
        {
            path: "/contentManagement/party",
            name: "party",
            meta: { title: "党群服务日志" },
            component: () => import('../views/contentManagement/party/index.vue')
        },
        {
            path: "/contentManagement/inventory",
            name: "inventory",
            meta: { title: "清单" },
            component: () => import('../views/contentManagement/inventory/index.vue')
        },
        {
            path: "/contentManagement/announcement",
            name: "announcement",
            meta: { title: "公告" },
            component: () => import('../views/contentManagement/announcement/index.vue')
        },
    ]

}
export default contentManagementRouter
