// import request from '../utils/requset';
import request from '../request/http';


const approval = {

    //获取审批模板列表
    approvalModulelist(params) {
        return request.get('/hemei-admin/approval/workflow/approval/module/list', { params })
    },
    //获取所有的审批人(带干部标签)
    approvalUidAll(params) {
        return request.get('/hemei-admin/approval/all/approvalUid/list', { params })
    },
    //分页_获取所有的审批人(带干部标签)
    pagerovalUidAll(params) {
        return request.get('/hemei-admin/approval/all/approvalUid/page', { params })
    },
    //获取流程配置的审批人
    approvalUid(params) {
        return request.get('/hemei-admin/approval/approvalUid/list', { params })
    },
    // 获取审批模板详情
    approvalModuledetail(params) {
        return request.get('/hemei-admin/approval/workflow/approval/module/detail', { params })
    },
    // 获取流程配置的审批人
    approvalUidlist(params) {
        return request.get('/hemei-admin/approval/approvalUid/list', { params })
    },
    // 保存审批人
    saveApproval(data) {
        return request.post('/hemei-admin/approval/save', data)
    },
    // 获取审批分页
    pageApproval(params) {
        return request.get('/hemei-admin/approval/workflow/page', { params })
    },
    // 获取审批分页
    detailApproval(params) {
        return request.get('/hemei-admin/approval/workflow/bind/approval/detail', { params })
    },
    // 村民编辑申请详情
    updatedetail(params) {
        return request.get('/hemei-admin/approval/workflow/update/user/approval/detail', { params })
    },

}

export default approval;