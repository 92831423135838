import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'
// import route from '../store/index'
import api from '../api/index'

// 乡村
import localityServeRouter from "./localityServe.js";

// // 仪表盘
import instrumentsPanel from './instrumentsPanel.js';
// // 用户
import uesrlist from './uesrlist.js';
// // 文章 
import articleRouter from './article.js';
// 电商
import ECommerceServiceRouter from './ECommerceService.js';
// 乡村资料设置
import dataSettingRouter from "./dataSetting.js";
// 设置
import setUserRouter from "./setUser.js";
import government from './government.js';
import localService from './localService.js';
import contentManagementRouter from './contentManagement.js';
import examineRouter from './examine.js';
import districtRouter from './district.js';





Vue.use(VueRouter)
export const routes = [
  {
    path: '/redirect',
    // path: '/Layout',
    component: Layout,
    hidden: true,
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('../index.vue')
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../login.vue')
  },

  instrumentsPanel,
  uesrlist,
  localityServeRouter,
  articleRouter,
  government,
  localService,
  ECommerceServiceRouter,
  dataSettingRouter,
  setUserRouter,
  contentManagementRouter,
  examineRouter,
  districtRouter,
  {
    path: '/401',
    component: () => import('@/views/error-page/401'),
    name: 'Page401',
    meta: { title: '401', noCache: true }
  },
  {
    path: '/404',
    component: () => import('@/views/error-page/404'),
    name: 'Page404',
    meta: { title: '404', noCache: true }
  },
  { path: '*', redirect: '/404', hidden: true }
]
const router = new VueRouter({
  routes
})
router.beforeEach((to, form, next) => {
  if (to.meta.title) {
    document.title = to.meta.title

  } else {
    document.title = '本地和美乡村'
  }
  next()

  //接下来写正常的路由守卫就可以了
})
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
// 路由拦截
router.beforeEach((to, from, next) => {
  next()
  let arr = JSON.parse(localStorage.getItem('routeList'))
  let routeID = localStorage.getItem('rouId')
  let page = to.path.split('/')
  let toPage = page[2] == 'productList' ? '/production/productList' : to.path
  let a = []
  if (arr) {
    arr.forEach(e => {
      if (!e.menuInfoList) return
      a.push(e.menuInfoList.some(obj => {
        // 存在路由时存储按钮
        if (obj.jump === toPage) {
          api.account.adminJurisdiction().then(res => {
            let data = res.data.data
            data.forEach(child => {
              if (Number(child.id) === Number(routeID)) {
                if (obj.menuInfoList) {
                  let childData = child.roule.split(',')
                  let control = []
                  obj.menuInfoList.forEach(son => {
                    childData.forEach(sonCd => {
                      if (Number(sonCd) === son.id) {
                        control.push(son.jump)
                      }
                    })
                  });
                  routes.commit('savePageButton', control)
                }
              }
            })
          })
        }
        return obj.jump === toPage
      }))
    })
  }
  if (
    a.includes(true) || toPage === '/index' ||
    toPage === '/Login' || toPage === '/blank' ||
    toPage === '/production/productListDialog' ||
    toPage === '/orderManagement/productOrderDetail'
  ) {
    next()
  }
})
export default router

